/*
 * @Author: 许波
 * @Date: 2021-07-08 22:58:46
 * @LastEditTime: 2021-09-28 23:40:12
 */
import loadScript from './loadScript';
import { mapKey } from '../config/index';

let AmpaLoading = false;

function MP() {
  AmpaLoading = true;
  return loadScript('https://webapi.amap.com/js/marker.js')
    .then(() => loadScript(`https://webapi.amap.com/maps?v=1.4.11&key=${mapKey}&plugin=AMap.PolyEditor,AMap.Walking,AMap.AdvancedInfoWindow,AMap.Transfer,AMap.Autocomplete,AMap.PlaceSearch,AMap.getPoints,AMap.Geocoder,AMap.Riding,AMap.Text,AMap.Driving,AMap.GeometryUtil`))
    .then(() => loadScript('https://cache.amap.com/lbs/static/TransferRender1230.js'))
    .then(() => loadScript('https://webapi.amap.com/ui/1.0/main.js?v=1.0.11'))
    .then(() => { AmpaLoading = false; })
    .catch(() => { AmpaLoading = false; });
}

let waitingAmapTimer;

export default function () {
  return new Promise((resolve) => {
    if (window.AMap) {
      resolve(window.AMap);
      return;
    }
    if (AmpaLoading) {
      waitingAmapTimer = setInterval(() => {
        if (!AmpaLoading) {
          clearInterval(waitingAmapTimer);
          resolve(window.AMap);
        }
      }, 300);
      return;
    }
    MP().then(() => {
      setTimeout(() => {
        resolve(window.AMap);
      }, 600);
    });
  });
}
