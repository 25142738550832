/*
 * @Author: 许波
 * @Date: 2021-07-08 22:59:50
 * @LastEditTime: 2021-08-26 23:11:18
 */
export default (url) => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.onerror = reject;
  document.head.appendChild(script);
  script.onload = () => {
    resolve('success');
  };
});
