/*
 * @Author: 许波
 * @Date: 2021-07-29 00:52:17
 * @LastEditTime: 2021-07-29 00:53:41
 */
import loadScript from './loadScript';

import initMap from './initAmap';

export const InitMap = initMap;

let WxSdkLoading = false;
let waitingWxSdkTimer;

export const loadWxJsSDK = () => new Promise((resolve) => {
  if (window.wx) {
    resolve(window.wx);
    return;
  }
  if (WxSdkLoading) {
    waitingWxSdkTimer = setInterval(() => {
      if (!WxSdkLoading) {
        clearInterval(waitingWxSdkTimer);
        resolve(window.wx);
      }
    }, 300);
    return;
  }
  WxSdkLoading = true;
  loadScript('/static/weixin/jweixin-1.6.0.js').then(() => {
    WxSdkLoading = false;
    resolve(window.wx);
  });
});

let loadQQGeolocationLoading = false;
let loadQQGeolocationTimer;
export const loadQQGeolocation = () => new Promise((resolve) => {
  if (window.qq) {
    resolve(window.qq);
    return;
  }
  if (loadQQGeolocationLoading) {
    loadQQGeolocationTimer = setInterval(() => {
      if (!loadQQGeolocationLoading) {
        clearInterval(loadQQGeolocationTimer);
        resolve(window.qq);
      }
    }, 300);
    return;
  }
  loadQQGeolocationLoading = true;
  loadScript('https://3gimg.qq.com/lightmap/components/geolocation/geolocation.min.js').then(() => {
    loadQQGeolocationLoading = false;
    resolve(window.qq);
  });
});
